import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import TextBanner from '../components/text-banner/text-banner'
import RichTextBlock from '../components/richTextBlock/RichTextBlock'

class PrivacyPolicyPage extends React.Component {
  render() {
    const termsConditionsHeader = get(this, 'props.data.termsConditionsHeader')
    const termsConditionsBody = get(this, 'props.data.termsConditionsBody')

    return (
      <Layout location={this.props.location}>
        <Seo title="Terms & Conditions" />
        <div className="container-fluid container-fluid--page">
          <TextBanner
            textOne={termsConditionsHeader.textOne}
            textTwo={termsConditionsHeader.textTwo}
          />
          <RichTextBlock data={termsConditionsBody.body} />
        </div>
      </Layout>
    )
  }
}

export default PrivacyPolicyPage

export const query = graphql`
  query TermsConditions {
    termsConditionsHeader: contentfulTextBanner(
      textBannerName: { eq: "TermsConditionsHeader" }
    ) {
      textBannerName
      textOne
      textTwo
    }
    termsConditionsBody: contentfulBasicPage(
      basicPage: { eq: "Terms & Conditions" }
    ) {
      body {
        raw
      }
    }
  }
`
